@use '@angular/material' as mat;
@mixin new-match-settings-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .nm-profile-page {
        background-color: mat.get-color-from-palette($background, card);
        &-toolbar {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            &-title {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
    }
}