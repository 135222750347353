@mixin profile-settings-page-common() {
    &.hybrid-mode {
        .settings-profile-page {
            .rpc-scroll-to-top-wrap {
                bottom: 64px;
                @include media-breakpoint("gt-xs") {
                    bottom: 64px;
                }
            }
        }
    }
    .settings-profile-page {
        &-toolbar {
            &-back {
                .rpc-icon-btn {
                    border-radius: 4px;
                }
            }
        }
    }
}