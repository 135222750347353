@mixin new-match-settings-page-common() {
    &.hybrid-mode {
        .nm-profile-page {
            .rpc-scroll-to-top-wrap {
                bottom: 64px;
                @include media-breakpoint("gt-xs") {
                    bottom: 64px;
                }
            }
        }
    }
    .nm-profile-page {
        &-toolbar {
            &-back{
                .rpc-icon-btn {
                    border-radius: 4px;
                }
            }
        }
    }
}