@mixin profile-toolbar-common() {
    // .saved-search-bottomsheet {
    //     &.mat-bottom-sheet-container {
    //         max-width: 600px;
    //         padding: 16px;
    //         border-radius: 10px 10px 0 0;
    //     }

    // }
    .profile-toolbar {
        &-back,
        &-btn {
            .rpc-icon-btn {
                border-radius: 4px;
            }
        }
    }
}
