@use '@angular/material' as mat;
@mixin knowledge-base-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .knowledge-base-toolbar {
        background-color: mat.get-color-from-palette($background, card);
        &-title {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}