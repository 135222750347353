@use "@angular/material" as mat;
@mixin general-info-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);
    .general-profile-page {
        &-toolbar {
            background-color: mat.get-color-from-palette($background, card);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            &-title {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
    }
}