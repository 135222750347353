@mixin knowledge-base-common() {
    &:not(.hybrid-mode) {
        .kb {
            &-toolbar {
                @include media-breakpoint("gt-md") {
                    display: none;
                }
            }
            &-header {
                &:before,
                &:after {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
                &-decoration {
                    &:before,
                    &:after {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                }
                .kb-container {
                    @include media-breakpoint("gt-md") {
                        padding-top: 50px;
                    }
                }
            }
            &-title {
                @include media-breakpoint("gt-md") {
                    display: block;
                }
            }
            &-search {
                @include media-breakpoint("gt-md") {
                    max-width: 600px;
                    transform: translateY(50%);
                }
                &-wrap {
                    @include media-breakpoint("gt-md") {
                        max-width: none;
                        padding-right: 40%;
                    }
                }
            }
            &-decoration {
                @include media-breakpoint("gt-md") {
                    display: block;
                }
            }
            &-body {
                @include media-breakpoint("gt-md") {
                    padding-top: 60px;
                }
            }
            &-tabs {
                .mat-tab-label-container {
                    @include media-breakpoint("gt-md") {
                        padding: 0 16px;
                    }
                }
            }
        }
    }
    .kb {
        &-search {
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 0 !important;
                    margin: 0 !important;
                }
                &-subscript-wrapper {
                    display: none !important;
                }
                &-appearance-outline {
                    &.mat-form-field-can-float {
                        &.mat-form-field-should-float {
                            .mat-form-field {
                                &-label {
                                    transform: translateY(-1.1em) scale(.75) !important;
                                }
                            }
                        }
                        .mat-form-field-label {
                            top: 1.2em !important;
                        }
                        .mat-input-server {
                            &:focus {
                                & + .mat-form-field-label-wrapper {
                                    .mat-form-field-label {
                                        transform: translateY(-1.2em) scale(.75) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-tabs {
            .mat-tab-label-container {
                width: 100%;
                max-width: 1000px;
                margin: 0 auto;
            }
            &.mat-tab-group {
                .mat-tab-body-content {
                    padding-inline: 0;
                }
            }
        }
    }
}