@mixin simple-dialog-common() {
    &:not(.hybrid-mode) {
        .confirm {
            &-actions--cancel {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: repeat(2, auto);
                }
            }
        }
    }
    .confirm {
        &-dialog {
            .mat-dialog-container {
                border-radius: 10px;
                padding: 0;
                max-width: 420px;
            }
        }
        &-actions {
            .rpc-btn-link {
                display: block;
                width: 100%;
            }
        }
    }
}
