@mixin folder-secondary-toolbar-common() {
    &:not(.hybrid-mode) {
        .internal-listings-toolbar {
            &.internal-listings-toolbar--top {
                .internal-listings-toolbar {
                    &-content {
                        @include media-breakpoint("gt-md") {
                            row-gap: 16px;
                            margin: 0 54px;
                        }
                    }
                    &-top,
                    &-bottom {
                        @include media-breakpoint("gt-md") {
                            border: none;
                        }
                    }
                    &-top {
                        @include media-breakpoint("gt-md") {
                            grid-template-columns: minmax(0, 1fr) min-content;
                            grid-template-areas: 
                                "title title"
                                "filter filter";
                            padding: 28px 16px 0;
                        }
                    }
                    &-title {
                        @include media-breakpoint("gt-md") {
                            grid-template-columns: auto 1px auto 1px auto;
                            justify-content: start;
                        }
                        &-back {
                            .rpc-btn-link {
                                @include media-breakpoint("gt-md") {
                                    padding: 0 12px;
                                }
                                &-text {
                                    @include media-breakpoint("gt-md") {
                                        display: block;
                                    }
                                }
                            }
                        }
                        &-menu {
                            .rpc-menu-btn-link {
                                &-text {
                                    @include media-breakpoint("gt-md") {
                                        font-size: 22px;
                                        line-height: 28px;
                                    }
                                }
                                &-trigger {
                                    @include media-breakpoint("gt-md") {
                                        font-size: 16px;
                                        margin: 0;
                                    }
                                }
                                .mat-button-wrapper {
                                    @include media-breakpoint("gt-md") {
                                        display: grid;
                                        grid-template-columns: 24px auto 16px;
                                        column-gap: 4px;
                                        justify-content: initial;
                                    }
                                }
                            }
                        }
                        &-counter {
                            @include media-breakpoint("gt-md") {
                                font-size: 18px;
                                line-height: 24px;
                                padding: 0 8px;
                            }
                        }
                        &-divider {
                            &--last {
                                @include media-breakpoint("gt-md") {
                                    display: none;
                                }
                            }
                        }
                    }
                    &-bottom {
                        @include media-breakpoint("gt-md") {
                            padding: 0 16px;
                        }
                    }
                }
            }
            &-top {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: auto minmax(0, 1fr);
                    grid-template-areas: "title filter";
                    column-gap: 16px;
                    padding: 6px 24px 5px;
                }
                &-sort {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                }
                &-switch {
                    @include media-breakpoint("gt-md") {
                        display: none;
                    }
                    &-mobile {
                        @include media-breakpoint("gt-md") {
                            display: none;
                        }
                    }
                    &-item {
                        @include media-breakpoint("gt-md") {
                            display: block;
                        }
                    }
                }
            }
            &-title {
                @include media-breakpoint("gt-md") {
                    display: grid;
                }
            }
            &-bottom {
                @include media-breakpoint("gt-md") {
                    grid-template-columns: minmax(0, 1fr) auto;
                    border-bottom: 0;
                    padding: 6px 24px;
                }
                &-right {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
            &-select {
                &-divider {
                    @include media-breakpoint("gt-md") {
                        display: block;
                    }
                }
            }
        }
    }

    .internal-listings-toolbar {
        &-top {
            &-sort {
                .rpc-menu-btn-link {
                    &-text,
                    &-trigger {
                        display: none;
                    }
                }
            }
        }
        &-title {
            &-back {
                .rpc-btn-link {
                    padding: 0;
                    min-width: 40px;
                    &-text {
                        display: none;
                    }
                }
            }
            &-menu {
                .rpc-menu-btn-link {
                    padding: 0 12px;
                    &-icon {
                        font-size: 24px;
                    }
                    &-text {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        margin: 0 !important;
                        transition: font-size .3s linear;
                        will-change: font-size;
                    }
                    &-trigger {
                        font-size: 16px;
                        margin: 0;
                    }
                    .mat-button-wrapper {
                        display: grid;
                        grid-template-columns: 24px auto 16px;
                        column-gap: 4px;
                        justify-content: initial;
                    }
                }
            }
        }
        &-select {
            &-dropdown {
                .rpc-dropdown-btn {
                    &--activity {
                        @media screen and (min-width: 400px) {
                            display: none;
                        }
                    }
                    &--middle {
                        &.rpc-dropdown-btn {
                            &:not(:first-child) {
                                .rpc-btn-link-text {
                                    &:before {
                                        @media screen and (min-width: 400px) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
